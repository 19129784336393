import React, { useState, useEffect } from 'react';
import './App.css';
import SignIn from './Components/SignIn';
import Dashboard from './Components/dashboard';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn');
    const storedUsername = localStorage.getItem('username');
    if (loggedIn === 'true' && storedUsername) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
    }
  }, []);

  const users = [
    { username: 'admin', password: 'vlh@123' },
    { username: 'xuong1', password: 'abc@123' },
    { username: 'xuong2', password: 'abc@123' },
    { username: 'xuong3', password: 'abc@123' },
    { username: 'hochau', password: 'abc@123' },
    { username: 'tantrieu', password: 'abc@123' },
    { username: 'phongkythuat', password: 'abc@123' },
    { username: 'thupkh', password: 'abc@123' },
    { username: 'phongqa', password: 'abc@123' },
    { username: 'tranglean', password: 'abc@123' },
    { username: 'xuong4', password: 'abc@123' },
    { username: 'phongpkh', password: 'abc@123' },

  ];

  const checkLogin = (inputUsername, password) => {
    const user = users.find(u => u.username === inputUsername && u.password === password);
    if (user) {
      setIsLoggedIn(true);
      setUsername(user.username);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('username', user.username);
      return true;
    }
    return false;
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('username');
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <Dashboard onLogout={handleLogout} username={username}/>
      ) : (
        <SignIn checkLogin={checkLogin} />
      )}
    </div>
  );
}

export default App;